/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PlanetDisplay } from '../../components/PlanetDisplay/PlanetDisplay'
import { useSharedDiaryQuery } from '../../reactQuery/useSharedDiaryQuery'
import LogoWhite from '../../images/logo_white.svg'

const ContainerDiv = styled.div`
  max-width: min(375px, 90vw);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 40px;
`

const CenterAlignedDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`

const DiaryImg = styled.img`
  object-fit: cover;
  max-height: min(375px, 90vw);
  width: min(375px, 90vw);
  min-height: min(375px, 90vw);
`

const AbsPlanetDiv = styled.div({
  position: 'absolute',
  bottom: '20px',
  left: '20px',
  display: 'flex',
  flexDirection: 'row',
  '& .planet': {
    fill: '#ffffff',
  },
  gap: '8px',
})

const TextColDiv = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '2px',
})

const UserNameDiv = styled.div({
  fontSize: '12px',
  lineHeight: '100%',
  fontWeight: '700',
  color: '#ffffff80',
})

const PlanetNameDiv = styled.div({
  fontSize: '14px',
  lineHeight: '100%',
  fontWeight: '700',
  color: '#ffffff',
})

const TextDiv = styled.div({
  marginBottom: '28px',
  lineHeight: '200%',
  color: '#ffffff80',
  '& .bold': {
    fontWeight: '900',
    color: '#ffffff',
  },
})

const BottomInfoDiv = styled.div({
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '200%',
  textAlign: 'center',
})

const PlainLink = styled.a({
  textDecoration: 'none',
  color: '#ffffff',
})

const WEEKDAY_NAMES = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY'
]

const MONTH_NAMES = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC'
]

export const SharedDiary = () => {
  const { publishToken = '' } = useParams()
  const sharedDiaryQuery = useSharedDiaryQuery(publishToken)
  const [msg, setMsg] = useState('다중우주 속 일기장을 뒤적이는 중...')
  let date = sharedDiaryQuery.data?.data?.diaryTimestamp !== undefined ? new Date(sharedDiaryQuery.data?.data?.diaryTimestamp) : new Date()
  let year = date.getFullYear()
  let month = MONTH_NAMES[date.getMonth()]
  let weekDay = WEEKDAY_NAMES[date.getDay()]
  let day = date.getDate().toString().padStart(2, '0')

  useEffect(() => {
    if (
      sharedDiaryQuery.isError ||
      (sharedDiaryQuery.data?.data === undefined && sharedDiaryQuery.isFetched)
    ) {
      setMsg('찾으시는 일기는 다중우주의 어딘가로 사라진 듯 합니다.')
    }
  }, [sharedDiaryQuery.isError])

  if (sharedDiaryQuery.data?.data !== undefined) {

    return (
      <ContainerDiv>
        <div style={{ position: 'relative', marginBottom: '64px' }}>
          <div 
            css={{
              paddingTop: '16px',
              paddingBottom: '16px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <svg height='18px' width='163px'>
              <image href={LogoWhite}></image>
            </svg>
          </div>
          <div
            css={{
              paddingTop: '6px',
              paddingBottom: '16px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#FFFFFF',
              lineHeight: '100%',
              fontWeight: 700,
            }}
          >
            <div css={{
              fontSize: '30px',
            }}>
              {day}
            </div>
            <div css={{
              width: '2px',
              height: '32px',
              backgroundColor: '#404040',
              margin: '0px 18px 0px 18px'
            }}>
            </div>
            <div css={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'start',
              gap: '2px',
              fontSize: '12px',
              lineHeight: '12px',
            }}>
              <div >
                {`${year}.${month}`}
              </div>
              <div>
                {weekDay}
              </div>
            </div>
          </div>
          <DiaryImg src={sharedDiaryQuery.data.data.imageUrl}></DiaryImg>
          <AbsPlanetDiv>
            <TextColDiv>
              <PlanetDisplay planetName={sharedDiaryQuery.data.data.planet} />
            </TextColDiv>
            <TextColDiv>
              <UserNameDiv>{sharedDiaryQuery.data.data.username}</UserNameDiv>
              <PlanetNameDiv>{sharedDiaryQuery.data.data.planet}</PlanetNameDiv>
            </TextColDiv>
          </AbsPlanetDiv>
        </div>
        <TextDiv>
          {sharedDiaryQuery.data.data.hashtags.length > 0 && (
            <div className="bold">{sharedDiaryQuery.data.data.hashtags}</div>
          )}
          {sharedDiaryQuery.data.data.text}
        </TextDiv>
        <BottomInfoDiv>
          <div>생성 24시간 후 소멸</div>
          <div>
            <PlainLink href="https://multiverseai.page.link/Eit5">[ 앱으로 가기 ]</PlainLink>
          </div>
        </BottomInfoDiv>
      </ContainerDiv>
    )
  } else {
    return <CenterAlignedDiv>{msg}</CenterAlignedDiv>
  }
}
