import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Fallback } from '../route/Fallback'
import { SharedDiary } from '../route/web/SharedDiary'

const router = createBrowserRouter([
  {
    path: '/web',
    children: [
      {
        path: 'shared/:publishToken',
        element: <SharedDiary />,
      },
    ],
  },
  {
    path: '*',
    element: <Fallback />,
  },
])

export const BrowserRouter = () => {
  return <RouterProvider router={router} />
}
