import axios from 'axios'
import { useQuery } from 'react-query'
import { MVDIARY_API_SERVER_URL } from '../const'
import { MvDiaryApiResponse } from './types'

type sharedDiary = {
  dateTime: string
  planet: string
  text: string
  hashtags: string
  imageUrl: string
  username: string
  diaryTimestamp: number
}

type SharedDiaryQueryResponse = MvDiaryApiResponse<sharedDiary>

export const useSharedDiaryQuery = (publishToken: string) => {
  return useQuery({
    queryKey: ['useSharedDiaryQuery', publishToken],
    queryFn: () => {
      let promise = new Promise<SharedDiaryQueryResponse>((resolve, reject) => {
        axios
          .get<SharedDiaryQueryResponse>(
            `${MVDIARY_API_SERVER_URL}/getPublishedSiteData?token=${publishToken}`
          )
          .then((value) => resolve(value.data))
          .catch((error) => reject(error))
      })
      return promise
    },
  })
}
