import { useEffect, useRef, useState } from 'react'
import Planet26 from './planet_26.svg'
import Planet29 from './planet_29.svg'
import Planet30 from './planet_30.svg'
import Planet31 from './planet_31.svg'
import Planet32 from './planet_32.svg'
import Planet34 from './planet_34.svg'
import Planet35 from './planet_35.svg'
import Planet36 from './planet_36.svg'
import Planet37 from './planet_37.svg'
import Planet38 from './planet_38.svg'

const imageSourceList = [
  Planet26,
  Planet29,
  Planet30,
  Planet31,
  Planet32,
  Planet34,
  Planet35,
  Planet36,
  Planet37,
  Planet38,
]

export interface PlanetDisplayProps {
  planetName: string
}

const getPlanetIndex = async (text: string) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(text)
  const hash = await crypto.subtle.digest('SHA-256', data)
  const hashArray = Array.from(new Uint8Array(hash)) // convert buffer to byte array
  const hashHex =
    '0x' + hashArray.map((b) => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string
  const bigNum = BigInt(hashHex)
  const index = bigNum % BigInt(10)
  return Number(index)
}

/**
 *
 * @param props
 * @returns 같은 행성 이름에 대해 언제나 동일한 svg를 표시한다.
 */
export const PlanetDisplay = (props: PlanetDisplayProps) => {
  const [planetString, setPlanetString] = useState('')

  useEffect(() => {
    getPlanetIndex(props.planetName).then((index) => {
      setPlanetString(imageSourceList[index])
    })
  })

  return (
    <svg height={'40px'} width={'40px'} viewBox="0 0 150 150">
      <image height="100%" width="100%" href={planetString} />
    </svg>
  )
}
