import { QueryClient, QueryClientProvider } from 'react-query'
import './App.css'
import { BrowserRouter } from './router/BrowserRouter'

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter />
    </QueryClientProvider>
  )
}

export default App
